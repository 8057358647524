const $document = $(document);

const loadSvgSprite = () => {
	if (typeof svgArray !== "undefined") {
		svgArray.forEach((value) => {
			const request = new XMLHttpRequest();

			request.open("GET", value, true);

			request.onload = () => {
				if (request.status >= 200 && request.status < 400) {
					const svgSprite = document.querySelector(".svg-sprite");
					svgSprite.innerHTML += request.responseText;
				}
			};

			request.send();
		});
	}
};

const carrocel = () => {
	$('.carroceloutter').each(function () {
		var $car = $(this), $ovf = $(this).find('.overflow'), $carrocel = $(this).find('.carrocel'), w = $(window).width();
		$car.data('step', 0);
		$ovf.css('margin-left', 0);
		if (w <= 770) {	
			$car.find('.cel').css('width', $car.width());
		} else {
			if ($carrocel.hasClass('cols3')) {
				$car.find('.cel').css('width', (($car.width() / 3) - 20));
			} else {
				$car.find('.cel').css('width', (($car.width() / 2) - 15));
			}
		}
		$car.find('.left').bind('click', function () {
			if ($car.data('step') >= 0) {
				return;
			}
			$car.data('step', $car.data('step') + 1);
			$ovf.stop().animate({
				marginLeft: ($car.data('step') * ($car.find('.cel:eq(0)').width() + 30)) + 1
			}, 700, function() {
				// Animation complete.
			});
		});
		$car.find('.right').bind('click', function () {
			if ($car.data('step') <= - $car.find('.cel').length + ($carrocel.hasClass('cols3') ? 3 : 2)) {
				return;
			}
			$car.data('step', $car.data('step') - 1);
			$ovf.stop().animate({
				marginLeft: ($car.data('step') * ($car.find('.cel:eq(0)').width() + 30)) + 1
			}, 700, function() {
				// Animation complete.
			});
		});
	});
};

const tout = () => {
	$('.tout').bind('click', function (e) {
		e.preventDefault();
		$('.active').removeClass('active');
		$(this).closest('.cel').addClass('active');
		$(this).closest('.overflow').addClass('active');
		$('.contentport').show();
		$('html, body').animate({
			scrollTop: $('#detalhes').offset().top
		}, 800, function(){
			// Add hash (#) to URL when done scrolling (default click behavior)
			//window.location.hash = hash;
		});
	});
};

const scrolltop = () => {
	$("a").on('click', function(event) {
		// Make sure this.hash has a value before overriding default behavior
		if (this.hash !== "") {
			// Prevent default anchor click behavior
			event.preventDefault();
			// Store hash
			var hash = this.hash;
			// Using jQuery's animate() method to add smooth page scroll
			// The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
			$('html, body').animate({
				scrollTop: $(hash).offset().top
			}, 800, function(){
				// Add hash (#) to URL when done scrolling (default click behavior)
				//window.location.hash = hash;
			});
		} // End if
	});
};

function App() {
	this.init = () => {
		loadSvgSprite();
		carrocel();
		$(window).on('resize', function(){
			carrocel();
		});
		tout();
		scrolltop();
	}
}

$document.ready(() => {
	const app = new App();
	app.init();
});
